.Editprofile {
    // padding-top: 160px;
    padding-bottom: 70px;
.copiedtext{
    color: var(--primary-color, #0E1120);
    margin-top: 12px;
    font-size: 12px;

}
    .rightmain {
        h6 {
            color: var(--primary-color, #0E1120);
            font-feature-settings: 'liga' off, 'clig' off;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            /* 19.2px */
            margin-bottom: 6px;
        }

        .maininner {
            display: flex;
            align-items: center;
            padding-bottom: 50px;

            .avatarimg {
                width: 130px;
                height: 130px;
                border-radius: 50%
            }

            .innered {
                margin-left: 27px;

                p {
                    color: var(--secondary-color, #67717D);
                    font-feature-settings: 'liga' off, 'clig' off;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 120%;
                    /* 14.4px */
                    margin-bottom: 18px;
                    width: 200px;
                }

                .inning {
                    display: flex;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 8px;

                    .upload-btn {
                        color: #222D37;
                        text-align: center;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        white-space: nowrap;
                        line-height: 100%;
                        /* 16px */
                        padding: 18px 40px;
                        border-radius: 15px;
                        background: #D0DEEF;
                        margin-bottom: 0px;
                        cursor: pointer;
                        &:hover {
                            background: #BDCBDC;
                        }
                    }

                    .outing {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 8px;
                        background: #FFFFFF;
                        border: 1px solid rgba(255, 40, 40, 0.1);
                        border-radius: 90px;
                        margin-left: 15px;

                        .trashimg {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
        }
    }

    .endbtn {
        border-radius: 15px;
        background: var(--button-color, #222D37);
        width: 100%;
        border: none;
        display: flex;
        padding: 20px 16px;
        justify-content: center;
        align-items: center;
        gap: 4px;
        flex: 1 0 0;
        color: var(--button-text-color, #E8F2FF);
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 24px */
text-transform: capitalize;
        margin-top: 15px;

        &:hover {
            background: #FF0083;

        }
    }

    .mainheadings {
        margin-bottom: 50px;
        // margin-top: 100px;

        .head {
            color: var(--primary-color, #0E1120);
            font-size: 54px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 54px */
        }

        .para {
            color: var(--primary-color, #0E1120);
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%;
            /* 22.5px */
        }
    }

    .maininput {
        position: relative;

        .copy {
            position: absolute;
            right: 14px;
            top: 19px;
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: var(--primary-color, #0E1120);
            text-decoration: none;
            border: none;
            background-color: transparent;
        }

        p {
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 100%;
            color: #fff;
            margin-bottom: 12px;

            .discordimg {
                width: 24px;
                height: 24px;
                margin-left: 10px;
            }
        }

        .innerinput {
            padding: 20px;
            color: var(--primary-color, #0E1120);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            /* 22.4px */
            width: 100%;
            border-radius: 15px !important;
            background: var(--text-feild-color, #DFEAFC) !important;
            border: 1px solid transparent;

            &::placeholder {
                color: var(--text-placeholder, #6E8491);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                /* 22.4px */

            }
            &:focus {
                border: 1px solid #0E1120 !important; /* Border on active state */
                outline: none; /* Optional: Removes the default outline on focus */
            }
        }

        // .innerinput {
        //     width: 100%;
        //     background: transparent;
        //     border: 1px solid #E2E2E2;
        //     border-radius: 5px;
        //     margin-bottom: 25px;
        //     padding: 14px;
        //     color: #fff;

        //     &::placeholder {
        //         font-style: normal;
        //         font-weight: 400;
        //         font-size: 16px;
        //         line-height: 140%;
        //         color: #D3D3D3;
        //     }
        // }
    }
}

.trash_icon {
    cursor: pointer;
}

@media (max-width:1200px) {
    .Editprofile .direction {
        display: flex;
        flex-direction: column-reverse;
    }

    .Editprofile {
        padding: 20px;
    }

    .Editprofile .direction .padd {
        padding: 0px;
        margin-bottom: 40px;
    }

    .uppper_part {
        width: 100% !important;
        padding: 50px 15px !important;

    }

    .uppper_inputs {
        flex-direction: column;
    }

    .innerinput {
        font-size: 10px !important;
    }
.uppper_part{
    .copy {
        right: 8px ;
        top: 5px;
    }
}

}

@media (max-width:400px) {
    .maininner {
        flex-direction: column !important;
        gap: 20px !important;
    }
}

.myyydirection {
    display: flex;
    flex-direction: column;

    .rightmain {
        display: flex;
        align-items: flex-start;
        gap: 50px;
    }
}

.linesshades {
    position: absolute;
    top: 0px;
    width: 100%;
    pointer-events: none;
}

.linesshades2 {
    position: absolute;
    top: 100px;
    right: 290px;
}

.linesshades3 {
    top: 314px;
    position: absolute;
    z-index: -1;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
}

.uppper_part {
    border-radius: 30px;
    padding: 58px 60px;
    width: 1200px;
    margin: 0 auto;
    border-radius: 15px;
    border: 1px solid var(--stroke, #DCE7F3) !important;
    background: var(--frame-color, #E7F2FF) !important;
    backdrop-filter: blur(35.099998474121094px);
}

label {
    color: var(--primary-color, #0E1120) !important;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 31.2px */
}

.uppper_inputs {
    display: flex;
    gap: 20px;
    align-items: center;
    width: 100%;
}

.firstttt {
    width: 100%;
    display: flex
;
    flex-direction: column;
    gap: 20px;
}
.terms {
    position: relative;
    height: 435px;
    background: #E8F2FF;

    .termpic {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    .innercontent {
        position: absolute;
        bottom: 146px;

        .grayspan {
            color: #67717D;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            gap: 8px;
            font-family: 'Pmedium';

            .pinkpara {
                color: #FF0083;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 120%;
            }
        }

        .blackpara {
            color: #0E1120;

            text-align: start;
            font-size: 60px;
            font-style: normal;
            font-weight: 500;
            line-height: 110%;
            margin-top: 24px;
        }
    }
}

.bottomtermofuse {
    background: #E8F2FF;
    padding: 40px 0px 70px 0px;
    position: relative;

    .shade {
        position: absolute;
        bottom: 0;
        left: 0;
    }

    .bottominnercontent {
        padding: 0px 200px;

        .shade {
            position: absolute;
            bottom: 0;
            left: 0;
        }

        .mainhead {
            color: #0E1120;
            max-width: 800px;
            width: 100%;
            font-size: 40px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            margin-bottom: 18px;
        }

        .mainpara {
            color: #67717D;
            max-width: 800px;
            width: 100%;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
        }

        .heading {
            color: #0E1120;
            font-size: 26px;
            font-style: normal;
            font-weight: 500;
            line-height: 120%;
            margin-bottom: 18px;
            margin-top: 25px;
            max-width: 800px;
            width: 100%;
        }


        .para1 {
            color: #67717D;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            max-width: 800px;
            width: 100%;

            span {
                color: #0E1120;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }
        }

        .para {
            color: #67717D;
            margin-bottom: 18px;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            max-width: 800px;
            width: 100%;

            a {
                color: #0E1120;
                font-size: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
            }
        }

        ol {

            list-style-position: outside;
            padding-left: 20px;

            li {
                color: #67717D;
                font-size: 18px;
                margin-bottom: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                max-width: 800px;
                width: 100%;
               

                &:last-child {
                    margin-bottom: 0px;
                }

                span {
                    color: #67717D;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                }
            }

            P {
                color: #67717D;
                font-size: 18px;
                margin-bottom: 18px;
                font-style: normal;
                font-weight: 400;
                line-height: 120%;
                max-width: 800px;
                width: 100%;
                a{
                    color: #0E1120;
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                }
            }
        }
    }
}

@media(max-width:600px) {
    .bottomtermofuse .shade {
        display: none;
    }

    .terms .innercontent {
        left: 30px;
    }

    .terms .innercontent .blackpara {
        font-size: 35px !important;
    }

    .bottomtermofuse .bottominnercontent {
        padding: 0px !important;
    }

    .bottomtermofuse .bottominnercontent .mainhead {
        font-size: 30px !important;
        max-width: 353px !important;
    }

    .bottomtermofuse .bottominnercontent .mainpara {
        font-size: 16px !important;
        max-width: 353px !important;
    }

    .bottomtermofuse .bottominnercontent .heading {
        font-size: 20px !important;
        max-width: 353px !important;
    }

    .bottomtermofuse .bottominnercontent .para {
        font-size: 16px !important;
        max-width: 353px !important;
    }

    .bottomtermofuse .bottominnercontent ul li {
        font-size: 16px !important;
        max-width: 353px !important;
    }

    .bottomtermofuse .bottominnercontent .para1 {
        font-size: 16px !important;
        max-width: 353px !important;
    }
}
.main-submit .main-inputs .inputmain .buttn .dropmain {
    height: 300px;
    overflow: scroll;
}

/* When the button is active */
.active-btn-show svg path {
    stroke: white;
    /* Change the stroke color to white */
}

/* Optional: if you want to set the color of the button itself */
.active-btn-show {
    background-color: #007bff;
    /* Example of changing the background color of the active button */
}

.main-submit {
    // padding-top: 160px;
    min-height: 100vh;

    .rdw-editor-toolbar {
        // display: none !important;
    }

    .proposalbgimg {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0px;
        width: 100%;
        height: 100%;
        min-height: 100%;
        object-fit: cover;
        object-position: center;
    }

    .buttn {
        label {
            color: var(--primary-color, #0E1120) !important;

            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            /* 26px */
        }
    }

    .innersubmit {
        padding: 58px 60px;
        border-radius: 15px;
        border: 1px solid var(--stroke, #DCE7F3);
        background: var(--frame-color, #E7F2FF);
        backdrop-filter: blur(35.099998474121094px);
        margin-bottom: 108px;
    }


    .btnnnss {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 10px;

        .arbtn {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 16px 40px;
            background: #ff0083;
            border-radius: 5px;
            border: none;
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 100%;
            color: #FFFFFF;
            border: 1px solid transparent;
            margin-right: 10px;

            &:hover {
                border: 1px solid #ff0083;
                color: #ff0083;
                background-color: transparent;
            }
        }
    }

    .topTicket {
        padding: 16px 20px;
        background: #ff0083;
        border-radius: 5px;
        width: 221px;
        justify-content: space-between;

        color: #ffffff;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
    }

    .main-inputs {
        .unlimited {
            height: 212px;
            border-radius: 15px;
            background: var(--text-feild-color, #DFEAFC);
            padding: 19px;
            overflow-y: scroll;
            margin-bottom: 20px;

            &::-webkit-scrollbar {
                display: none;
            }
        }

        .topmt {
            margin-top: 20px;
        }

        .upperhead {
            margin-bottom: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .custom-control-label::before {
                width: 20px;
                height: 20px;
                background: transparent;
                border: 1.5px solid #FF0083;
                border-radius: 5px;
            }

            .custom-control-label::after {
                top: 4px;
                left: 1px;
            }

            .custom-control-input:focus~.custom-control-label::before {
                box-shadow: none !important;
            }

            .custom-checkbox .custom-control-input:checked~.custom-control-label::after {
                background-color: #FF0083;
                border-radius: 5px;
                width: 20px;
                height: 20px;
                background-image: url(/Assets/Vector.svg);
            }

            .head {
                font-style: normal;
                font-weight: 700;
                font-size: 20px;
                line-height: 100%;
                text-align: left;
                color: #fff;
            }
        }

        .inputmain {

            .hinput {
                height: 137px;
                background: transparent;
                border: 1px solid #E2E2E2;
                border-radius: 10px;
                padding: 18px;
                margin-bottom: 16px;

                &:focus-visible {
                    outline: none !important;
                }

                &::placeholder {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 100%;
                    color: #C8C8C8;
                }
            }

            .buttn {
                .dropbtn {
                    background: transparent;
                    border-radius: 39px;
                    border: unset;
                    width: 100%;
                    padding: 20px;
                    max-width: 530px;
                    border-radius: 15px;
                    background: var(--text-feild-color, #DFEAFC);
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 16px;

                    .text-dark {

                        color: #0E1120 !important;
                        font-size: 20px !important;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 100%;
                        /* 20px */
                    }

                    .imgbtn {
                        width: 24px;
                        height: 24px;
                        transition: all 0.7s ease;
                        /* Transition effect lagaya */

                    }

                    &:focus {
                        box-shadow: none;
                    }

                    &:active {
                        color: #C8C8C8 !important;
                    }

                    &:focus-within {
                        color: #C8C8C8 !important;
                    }

                }

                .dropmain1 {
                    width: 100%;
                    height: unset;
                    overflow: scroll;
                    width: 100%;
                    border-radius: 18px;
                    border: 1px solid #D6E0EB;
                    background: #E7F2FF !important;
                    padding: 0px;
                    margin-top: 15px;
                    z-index: 999999;
                    cursor: pointer;

                    .item1 {
                        border-bottom: 1px solid #DCE7F3 !important;

                        padding: 16px;

                        margin-bottom: 0px;
                        padding-bottom: 10px;
                        color: #0E1120 !important;


                        font-size: 20px !important;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 100%;
                        /* 20px */

                        &:active {
                            background-color: transparent;
                        }

                        &:hover {
                            background: #E0ECFA !important;
                            transition: none;
                        }

                        &:last-child {
                            border-bottom: none !important; // Border remove kar diya gaya hai
                        }
                    }
                }

                ::-webkit-scrollbar {
                    display: none;
                }

                .dropmain {
                    width: 100%;
                    padding: 0px;
                    border: 1px solid #DCE7F3;
                    background: #E7F2FF;
                    z-index: 999;
                    height: unset !important;
                    border-radius: 18px;
                    border: 1px solid #D6E0EB;
                    margin-top: 10px;
                    margin-bottom: 10px;
                    overflow: scroll;

                    .item1 {
                        border-bottom: 1px solid #DCE7F3;
                        background: #E7F2FF;
                        padding: 16px;

                        margin-bottom: 0px;
                        border-bottom: 1px solid #DCE7F3;
                        color: #0E1120;
                        font-size: 20px;
                        font-style: normal;
                        z-index: 9999;
                        font-weight: 400;
                        line-height: 100%;
                        /* 20px */

                        &:active {
                            background-color: transparent;
                        }

                        &:hover {
                            background-color: unset !important;
                        }

                        &:last-child {
                            border-bottom: none; // Border remove kar diya gaya hai
                        }
                    }

                    a:hover {
                        color: unset !important;

                    }

                    .item1:hover {
                        background: #E0ECFA !important;
                        cursor: pointer;
                        transition: none;
                    }
                }
            }

            p {
                color: var(--primary-color, #0E1120) !important;
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 130%;

                word-break: break-all;
                /* 26px */
                margin-bottom: 10px;
            }

            input {
                border: 1px solid transparent;
                max-width: 530px;
                width: 100%;
                border-radius: 15px;
                background: var(--text-feild-color, #DFEAFC) !important;
                outline: none;
                color: var(--White, #FFF);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                color: var(--primary-color, #0E1120) !important;

                margin-bottom: 20px;

                &::placeholder {
                    color: var(--text-placeholder, #6E8491);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 140%;
                    /* 22.4px */
                }
            }
        }
    }

    .main-heading {

        .proposal_mainss {
            display: grid;
            grid-template-columns: 1fr 2fr;
            gap: 50px;

            a {
                width: 100%;
            }
        }

        h2 {
            color: var(--primary-color, #0E1120);
            font-size: 54px;
            font-style: normal;
            font-weight: 500;
            line-height: 100%;
            /* 54px */
            margin-bottom: 20px;
        }

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 140%;
            color: #FFF;
            margin-bottom: 10px;
        }

        input {
            border-radius: 15px;
            background: var(--text-feild-color, #DFEAFC) !important;
            color: var(--primary-color, #0E1120);
            border: 1px solid transparent;
            font-size: 16px;
            width: 100%;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            /* 22.4px */
            padding: 20px;

            &::placeholder {
                color: var(--text-placeholder, #6E8491);
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                /* 22.4px */
            }
        }
    }

    .textareadescription {
        margin-top: 20px;
        margin-bottom: 10px;

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 140%;
            color: #000;
            margin-top: 10px;
        }

        .productarea {
            border-radius: 39px;
            border: 1px solid var(--Accent, #FF0083);
            max-width: 530px;
            width: 100%;
            padding: 10px 12px 10px 24px;
            background-color: transparent;
            outline: none;
            color: var(--White, #FFF);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            color: #fff !important;

            &::placeholder {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 100%;
                color: #c8c8c8;

            }
        }
    }

    .enddate {
        position: relative;

        p {
            font-style: normal;
            font-weight: 400;
            font-size: 16px !important;
            line-height: 140%;
            color: #FFF;
            margin-bottom: 10px;
        }

        input {
            border-radius: 39px;
            border: 1px solid var(--Accent, #FF0083);
            max-width: 530px;
            width: 100%;
            padding: 10px 12px 10px 24px;
            background-color: transparent;
            outline: none;
            color: var(--White, #FFF);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 140%;
            color: #fff !important;

            &::placeholder {
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 100%;
                color: #c8c8c8;
            }
        }

        .imagess {
            position: relative;
            display: inline;

            img {
                position: absolute;
                top: 0px;
                right: 10px;
            }

            input[type="date"]::-webkit-calendar-picker-indicator {
                cursor: pointer;
                opacity: 1;
                display: block;
                background: url('../../Assets/calendar-2.svg') no-repeat;
                width: 20px;
                height: 20px;
            }
        }
    }

    .submitbutton {
        button {
            border-radius: 15px;
            background: var(--button-color, #222D37);
            padding: 20px 16px;

            width: 100%;
            border: unset;
            color: var(--button-text-color, #E8F2FF);
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 150%;
            /* 24px */
            text-transform: capitalize;

            &:hover {
                background: #FF0083;

            }
        }
    }

    .rdw-editor-main {
        height: 152px;

        // padding-top: 20px;
        &::-webkit-scrollbar {
            display: none;
        }
    }

    .active-content {
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 10px;

        a {
            width: 100%;

            &:last-child {
                margin-right: 0;
            }
        }

        button {
            border-radius: 15px;
            background: #D0DEEF;
            padding: 16px;
            color: #222D37;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 14px */
            display: flex;
            align-items: center;
            gap: 10px;
            width: 100%;
            border: unset;

            &:last-child {
                margin-right: 0;
            }

            .change-color-svg path {
                fill: #FFF;
            }

        }

        .active-btn-show {
            border-radius: 15px;
            background: #222D37;
            color: var(--button-text-color, #E8F2FF);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 100%;
            /* 14px */
        }

        .stroke-set path {
            fill: unset !important;
            stroke: #fff !important;
        }

    }
}

.simple_proposal_content {
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 50px;
}

.confirmmodal {

    .modal-dialog {
        .modal-content {
            border-radius: 15px;
            background: var(--frame-color, #E7F2FF);
            backdrop-filter: blur(92px);
            padding: 30px;

        }

        .modal-body {
            padding: 0px;

            .main-heading {
                position: relative;
                border-radius: 10px;
                border: 1px solid rgba(255, 255, 255, 0.10);
                padding: 0px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                h3 {
                    color: var(--primary-color, #0E1120);
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 100%;
                    /* 20px */
                    text-transform: capitalize;
                    text-align: start;
                }

                .crossimg {
                    position: absolute;
                    top: 0px !important;
                    right: 0px !important;
                    opacity: unset !important;
                }
            }

            .success {
                text-align: center;
                margin-top: 44px;
                margin-bottom: 15px;

                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 29px;
                    color: var(--primary-color, #0E1120);
                    margin-bottom: 39px;
                }
            }

            .confirmbtn {
                text-align: center;

                button {
                    border-radius: 15px;
                    background: var(--button-color, #222D37);
                    padding: 14px 100px;
                    align-items: center;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 100%;
                    text-align: center;
                    color: #ffffff;
                    border: none;
                    cursor: pointer;
                    // width: 449px;
                    width: 100%;
                    margin-bottom: 20px;
                }
            }

            .Submitproposal {
                text-align: center;

                h6 {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 29px;
                    letter-spacing: 0.02em;
                    color: #171515;
                    margin-top: 105px;
                    margin-bottom: 101px;
                }
            }

            .balance {
                text-align: center;
                margin-top: 92px;

                h6 {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 24px;
                    line-height: 29px;
                    letter-spacing: 0.02em;
                    color: #171515 !important;
                }

                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 22px;

                    letter-spacing: 0.02em;

                    color: #f21d1d;
                    margin-top: 5px;
                    margin-bottom: 87px;
                }
            }

            .upper-img {
                margin: 30px 0;
            }

            .sure_texxt {
                color: var(--primary-color, #0E1120);
                text-align: center !important;
                font-size: 28px !important;
                font-style: normal;
                font-weight: 500 !important;
                line-height: 100%;
                /* 28px */
                border: unset !important;
                padding: 0px  !important;
                margin: 0 auto !important;
                display: flex !important;
                align-items: center !important;
                justify-content: center !important;
            }

            .ptagofaddfund {
                color: var(--secondary-color, #67717D);
                text-align: center;
                padding-top: 10px;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 140%;
                /* 22.4px */
            }

            .balance_div {
                padding: 20px;
                border-radius: 15px;
                border: 1px solid var(--stroke, #DCE7F3);
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 20px;
                margin-bottom: 20px;
            }

            .submitfee-text {
                h5 {
                    padding: 20px;
                    border-radius: 15px;
                    border: 1px solid var(--stroke, #DCE7F3);
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: var(--primary-color, #0E1120);
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-top: 20px;
                    margin-bottom: 30px;

                    span {
                        color: var(--primary-color, #0E1120) !important;
                        text-align: center;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: normal;
                    }
                }

                p {
                    color: var(--secondary-color, #67717D);
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    text-align: center;
                    line-height: 140%;
                    /* 22.4px */
                    padding-top: 10px;
                }

                h6 {
                    color: var(--primary-color, #0E1120);
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    margin-bottom: 5px;

                }


                h4 {
                    color: #FFF;
                    text-align: center;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                span {
                    color: var(--primary-color, #0E1120);
                    text-align: right;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                }

                button {
                    color: var(--button-text-color, #E8F2FF);
                    width: 100%;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;
                    text-transform: capitalize;
                    padding: 15px 0px;
                    border-radius: 15px;
                    background: var(--button-color, #222D37);
                    border: unset;
                }
            }
        }
    }
}

.confirmmodal .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Full viewport height */
    margin: 0 auto;
}

.modal-content {
    width: 100%;
    max-width: 500px;
    /* Optional: Adjust the max width as needed */
}

/* Optional: To ensure the modal does not scroll, if the content exceeds the viewport */
.modal-body {
    overflow-y: auto;
}

.rdw-editor-main {
    // background-color: #fafafa;
    background-color: #fff;
    border: 1px solid transparent;
    border-radius: 6px;
    padding-left: 14px;
    padding-right: 14px;

    &:hover {
        border: 1px solid #BDCBDC;


    }

    &:focus {
        border: 1px solid #0E1120 !important;
        /* Border on active state */
        outline: none;
        /* Optional: Removes the default outline on focus */
    }
}

.error {
    color: #f21d1d !important;
}

.close {
    opacity: unset !important;
}

.buttn.show {

    .dropbtn {
        color: #393939 !important;

        .imgbtn {
            transform: rotate(180deg);
            transition: 0.3s;
        }
    }
}

@media (max-width:991px) {
    .main-submit .main-inputs .upperhead .custom-control-label::after {
        left: 0px;
    }
}

.submit-simple {
    background: #FF0083;
    border-radius: 10px;
    padding: 20px;
    border: none;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 100%;
    color: #FFFFFF;
    width: 100%;
}

.toast-success {
    .fade.show {
        border-radius: 9px;
        border: none;
    }

    border-radius: 9px;
    border: none;

    .toast-header {
        background: #6AC259;
        border-radius: 9px;
        justify-content: space-between;
        padding: 15px;
        position: relative;

        .btn-close {
            background: url("../../Assets/toast-close.svg");
            position: absolute;
            top: 10px;
            right: 15px;
            z-index: 999;
            opacity: 1;
        }

        .parent {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .left {
                margin-right: 10px;
            }

            .right {
                h6 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 100%;
                    color: #FFFFFF;
                    margin-bottom: 6px;
                }

                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 100%;
                    color: #FFFFFF;
                }
            }
        }
    }
}

.toast-error {
    .fade.show {
        border-radius: 9px;
        border: none;
    }

    border-radius: 9px;
    border: none;

    .toast-header {
        background: #F05228;
        border-radius: 9px;
        justify-content: space-between;
        padding: 15px;
        position: relative;

        .btn-close {
            background: url("../../Assets/toast-close.svg");
            position: absolute;
            top: 10px;
            right: 15px;
            z-index: 999;
            opacity: 1;
        }

        .parent {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .left {
                margin-right: 10px;
            }

            .right {
                h6 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 100%;
                    color: #FFFFFF;
                    margin-bottom: 6px;
                }

                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 100%;
                    color: #FFFFFF;
                }
            }
        }
    }
}

.toast-warning {
    .fade.show {
        border-radius: 9px;
        border: none;
    }

    .toast-header {
        background: #FFCC4D;
        border-radius: 9px;
        justify-content: space-between;
        padding: 15px;
        position: relative;
        border: none;

        .btn-close {
            background: url("../../Assets/toast-close.svg");
            position: absolute;
            top: 10px;
            right: 15px;
            z-index: 999;
            opacity: 1;
        }

        .parent {
            display: flex;
            align-items: center;
            justify-content: flex-start;

            .left {
                margin-right: 10px;
            }

            .right {
                h6 {
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    line-height: 100%;
                    color: #FFFFFF;
                    margin-bottom: 6px;
                }

                p {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 100%;
                    color: #FFFFFF;
                }
            }
        }
    }
}

.my-dp {
    margin-bottom: 20px;

    .dropdown-toggle {
        background: transparent;
        border: 2px solid #ff0083;
        padding: 15px;
        border-radius: 10px;
        margin: 0 auto;
        display: block;
    }
}

@media(max-width:991px) {
    .main-submit .active-content {

        gap: 20px;
    }

    .main-submit .main-heading .proposal_mainss {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 50px;
        gap: 0px;
    }

    .main-submit .active-content {
        grid-gap: 0px;
        gap: 10px !important;
        flex-direction: unset !important;
        overflow-y: auto;
    }

    label {
        color: #0E1120 !important;
        color: var(--primary-color, #0E1120) !important;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
    }

    .main-submit .main-inputs .inputmain p {
        color: #0E1120 !important;
        color: var(--primary-color, #0E1120) !important;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        margin-bottom: 10px;
    }

    .main-submit .active-content button {
        border-radius: 15px;
        background: #D0DEEF;
        padding: 16px;
        color: #222D37;
        font-size: 14px;
        font-style: normal;
        white-space: nowrap;
        font-weight: 400;
        line-height: 100%;
        display: flex;
        align-items: center;
        grid-gap: 10px;
        gap: 10px;
        width: 100%;
        border: unset;
    }

    .main-submit .innersubmit {
        padding: 25px 25px;
        // border-radius: 30px;
        // border: 1px solid #FF0083;
        // background: linear-gradient(103deg, rgba(255, 160, 198, 0.06) 0%, rgba(126, 126, 126, 0) 110.97%), rgba(29, 29, 29, 0.05);
        // -webkit-backdrop-filter: blur(35.0999984741px);
        // backdrop-filter: blur(35.0999984741px);
        // margin-bottom: 120px;
    }

    .main-submit .main-heading h2 {
        // color: #FFF;

        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: 100%;
        margin-bottom: 20px;
    }

    .simple_proposal_content {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 50px;
        gap: 0px;
    }
}


@media (max-width:600px) {

    .main-submit .active-content a {
        margin: 0 auto;
        margin-bottom: 20px;
        width: 100%;
    }

    .main-submit .active-content button:last-child {
        width: 100%;
    }

    .set-sm-text {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 300px;
        width: 100%;
    }

    .main-submit .active-content {
        gap: 0px;
        flex-direction: column;
    }
}







.surediv {
    color: #FFF;
    text-align: center;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 600;
    line-height: 100%;
    /* 24px */
    padding-bottom: 10px;
}

.mydvvvvvv {
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid rgba(240, 240, 240, 0.10);
    padding: 20px;
    justify-content: space-between;
    margin-top: 25px;

}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto;
    backdrop-filter: blur(10px);
}

.modal {
    z-index: 2000000;
    backdrop-filter: blur(10px);
}

.public-DraftStyleDefault-ltr {
    margin: 0px !important;
    padding-top: 20px !important;
}
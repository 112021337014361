.banner-main {
  background: #E7F2FF;

  .pinkbtn {
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    /* 18px */
    text-transform: capitalize;
    padding: 14px 30px;
    border-radius: 15px;
    background: #222D37;
    border: unset;
    // margin-top: -48px;

    &:hover {
      background: #FF0083;

  }
  }

  .pinkbg {
    position: absolute;
    top: 0px;
    width: 100%;
  }

  .topTicket {
    padding: 16px 20px;
    background: #ff0083;
    border-radius: 5px;
    width: 221px;
    justify-content: space-between;

    color: #ffffff;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
  }

  .governancebox {
    padding-top: 0px;

    // h5 {
    //   font-style: normal;
    //   font-weight: 700;
    //   font-size: 25px;

    //   color: #2e3433;
    // }

    h3 {
      font-style: normal;
      font-weight: 900;
      font-size: 45px;
      line-height: 110%;
      text-align: center;
      color: #fff;
      padding-top: 50px;
      padding-bottom: 50px;
    }

    span {}

    .futttture {
      color: #0E1120 !important;
      font-size: 65px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
    }

    p {
      color: var(--secondary-color, #67717D);
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      /* 24px */
      /* 27px */
      text-align: center;
      margin-top: 28px;
      margin-top: 0px;
    }
  }

  .treasuryBox {
    gap: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    padding: 0px 20px;
    margin-top: 50px;




    .treasuryfirst {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      border-radius: 20px;
      border: 1px solid #DCE7F3;
      background: #E7F2FF;
      flex-wrap: wrap;
      flex-grow: 1;
      padding: 30px;

      p {
        color: #67717D;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        /* 16.8px */
        padding-top: 20px;
        padding-bottom: 5px;
      }

      h3 {
        color: #0E1120;
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        /* 36px */
      }
    }

    .treasuryP {
      padding: 15px 20px;
      border-left: 1px solid #b3a8bc;

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 150%;
        color: #767676;
      }
    }
  }

  .ptagssspan {
    color: #959595;
    padding-right: 17px;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    /* 20px */
  }

  .porposalDiv {
    margin-top: 97px;
    padding: 25px;
    border-radius: 20px;
    border: 1px solid #DCE7F3;
    background: #E7F2FF;
    margin-bottom: 50px;

    .timeDiv {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-top: 10px;

      .dullbutton {
        display: flex;
        padding: 0px 40px;
        height: 39px;
        justify-content: center;
        align-items: center;
        gap: 5px;
        color: #b6b6b6;
        border-radius: 225px;
        background: #222;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        margin-right: 15px;
        border: none;
      }

      .timeDiv:hover {
        color: unset;
      }

      .banner_parent_Div:hover {
        color: unset;
      }

      .banner_parent_Div {
        padding: 25px;
        border-radius: 15px;
        background: #E0ECFA;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .bottom_side {
          display: flex;
          align-items: center;
gap: 30px;
          .inner_text_bottom {
            text-align: center;

            h2 {
              color: #0DA518;
              text-align: center;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%;
              /* 14.4px */
            }

            p {
              color: #0E1120;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              padding-top: 10px;
              line-height: 120%;
              /* 24px */
            }

            h4 {
              color: #CF1A12;
              text-align: center;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%;
              /* 14.4px */
            }

            h6 {
              color: #67717D;
              text-align: center;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: 120%;
              /* 14.4px */
            }
          }

        }
      }

      .noDiv {
        display: flex;
        align-items: flex-start;
        flex-direction: column;


        .button_top {
          display: flex;
          align-items: center;
          gap: 12px;

          .ptagssspan {
            color: #67717D;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            padding: 5px 12px;
            height: 28px;
display: flex;
align-items: center;
justify-content: center;
            border-radius: 15px;
            background: rgba(103, 113, 125, 0.09);
            text-transform: capitalize;
          }

          .greyButtons {
            color: #0DA518;
            text-align: center;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            /* 18px */
            display: flex;
            align-items: center;
            justify-content: center;
            text-transform: capitalize;
            padding: 5px 12px;
            border-radius: 15px;
            // background: #C7E7DD !important;
            height: 28px;
          }

        }

        p:nth-child(1) {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          color: #959595;
          margin-right: 17px;
        }

        p:nth-child(2) {
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          color: #fff;
        }
      }

      .innnerptag {
        color: #0E1120 !important;
        font-feature-settings: 'liga' off, 'clig' off;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        /* 26px */
        margin-top: 14px;
        width: 734px;
        flex-wrap: wrap;
      }

      .greyButton {
        padding: 5px 15px;
        display: flex;
        align-items: center;
        gap: 5px;
        border-radius: 225px;
        background: #222;
        outline: none;

        .card-heading-4 {
          color: #AFAFAF;
text-align: center;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 18px */
text-transform: capitalize;
        }

        p {
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          color: #b6b6b6;
        }

        h5 {}
      }

      .greyButtons1 {
        border-radius: 5px;
        display: flex;
        align-items: center;
        padding: 9px 15px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        color: #ffffff;
        outline: none;
        height: 100%;
        border: none;
        box-shadow: none;
      }
    }

    .porp {
      color: #0E1120;
      font-size: 30px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
    }

    .porps {
      margin-right: 127px;
    }

    .walletDiv {
      p {
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        text-transform: uppercase;
        color: #767676;
      }

      .btn-porposal {
        padding: 16px 20px;
        border: 1px solid transparent;

        &:hover {
          background-color: transparent;
          border: 1px solid #ff0083;
          color: #ff0083;
          border-radius: 5px;
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          height: 100%;
          padding: 16px 20px;
          text-decoration: none !important;
        }
      }
    }
  }

  .brdr {
    border: 1px solid#DCE7F3;
    margin-top: 50px;
    margin-bottom: 50px;
  }

  .comments {
    .comments-heading {
      margin-bottom: 20px;

      h2 {
        color: var(--primary-color, #0E1120);
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        /* 31.2px */
      }
    }

    .commentstextarea {
      p {
        color: var(--primary-color, #0E1120);
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        /* 26px */
        margin-bottom: 10px;
      }

      .commentstext {
        height: 152px;
        padding: 20px;
        outline: none;
        color: var(--primary-color, #0E1120);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 100%;
        /* 16px */
        border-radius: 15px;
        background: var(--text-feild-color, #DFEAFC);
        border: 1px solid transparent;
        &:hover {
          border: 1px solid #BDCBDC !important;


        }
        &:focus {
          border: 1px solid #0E1120 !important; /* Border on active state */
          outline: none; /* Optional: Removes the default outline on focus */
        }
        &::placeholder {
          color: var(--text-placeholder, #6E8491);
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 100%;
          /* 16px */
        }
      }
    }

    .btnpost {
      margin-top: 30px;

      button {
        color: var(--button-text-color, #E8F2FF);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        white-space: nowrap;
        /* 16px */
        width: 186px;
        padding: 20px 40px;
        border-radius: 15px;
        background: var(--button-color, #222D37);
        border: unset;
        &:hover {
          background: #FF0083;

        }
      }

      .btnnnna {
        color: var(--button-text-color, #E8F2FF);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        /* 16px */
        width: 186px;
        padding: 20px 40px;
        border-radius: 15px;
        background: var(--button-color, #222D37);
        border: unset;
      }
    }
  }

  .comment {
    .headingsss {
      margin-bottom: 40px;

      h2 {
        color: var(--primary-color, #0E1120);
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 130%;
        /* 31.2px */
        border-top: 1px solid #DCE7F3;
        padding-top: 30px;
      }
    }

    .parent {
      display: flex;
      align-items: center;

      .left-content {
        margin-right: 13.2px;

        img {
          width: 60px;
          height: 60px;
          object-fit: cover;
          border-radius: 50%;
        }
      }

      .right-content {
        .doyle {
          .doylehead {
            color: var(--primary-color, #0E1120);
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 130%;
            /* 26px */
          }

          p {
            color: var(--secondary-color, #67717D);
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 130%;
            /* 20.8px */
            margin-top: 2px;
          }
        }
      }
    }

    .mainpara-comment {
      .para {
        margin-top: 26.2px;

        p {
          color: var(--secondary-color, #67717D);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 130%;
          /* 18.2px */
        }
      }
    }
  }
}
.mydivphone {
  padding-bottom: 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bg-primary {
  color: #7D8792 !important;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 150%;
  /* 18px */
  text-transform: capitalize;
  padding: 5px 12px;
  border-radius: 15px;
  background: #D8E3EF !important;
  border: unset;
}

.top_proposal_div {
  padding: 0px 20px;
}

.main-navbar-mobile {
  height: unset !important;
}

.mydataa {
  .blank__div {
    color: #FF0083;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 16.8px */
  }

  a:hover {
    color: unset !important;
  }

  h5 {
    color: #67717D !important;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 120%;
    /* 16.8px */
    padding: 12px !important;
    border-radius: 10px !important;
    background: #E0ECFA;
    display: inline-flex;
    align-items: center;
    gap: 10px;
  }

  span {
    color: #FF0083;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 16.8px */
  }

  h3 {
    color: #0E1120 !important;
    font-feature-settings: 'liga' off, 'clig' off;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500 !important;
    text-align: start !important;
    align-items: flex-start !important;
    line-height: 130%;
    /* 31.2px */
    width: 730px;
    flex-wrap: wrap;
    padding-top: 16px !important;
    padding-bottom: 25px !important;
  }
}

.parent__against {
  padding: 25px;
  border-radius: 15px;
  border: 1px solid #DCE7F3;
  background: #E7F2FF;
  margin-bottom: 15px;
}

.middle_proposal_section {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 60px;
}

.comments {
  margin-bottom: 70px;
}

.main_mid {
  padding: 25px;
  border-radius: 15px;
  border: 1px solid #DCE7F3;
  background: var(--frame-color, #E7F2FF);

  h5 {
    color: #67717D;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 18px */
    text-transform: capitalize;
    padding: 5px 12px;
    border-radius: 15px;
    background: rgba(103, 113, 125, 0.09);
  }
}

.anotherdiv {
  display: flex;
  align-items: center;
  gap: 9px;
}
.inteer_neww_Dtaaa{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.submit_proposal_button {
  button {

    border: unset;
    color: #FFF;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    text-transform: capitalize;
    padding: 16px 20px;
    border-radius: 15px;
    background: #0E6AAD;
    &:hover{
      background: #298AD0;
  }
  }
}

@media (max-width: 827px) and (min-width: 768px) {
  .banner-main .porposalDiv .timeDiv .greyButton {
    // background: #f1f1f1;
    // border-radius: 5px;
    // margin-right: 3.5px;
    // display: flex;
    // align-items: center;
    // padding: 9px 7px;
    // outline: none;
  }

  .banner-main .countSection .ThresholdDiv {
    margin: 20px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px !important;
    background: #ffffff;
    border: 1px solid #e2e2e2;
    border-radius: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.02em;
    color: #989898;
    font-style: normal;
  }
}
@media (max-width: 600px) {
  .banner-main .porposalDiv {
    margin-top: 97px;
    padding: 10px;
    border-radius: 20px;
    border: 1px solid #DCE7F3;
    background: #E7F2FF;
    margin-bottom: 50px;
}
.banner-main .porposalDiv .timeDiv .greyButton .card-heading-4 {
  color: #AFAFAF;
  text-align: center;
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-transform: capitalize;
}
.banner-main .bg-secondary {
  color: #197CB9 !important;
  text-align: center;
  font-size: 9px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: 150%;
  text-transform: capitalize;
  padding: 5px 12px !important;
  border-radius: 15px !important;
  background: #C9E1F5 !important;
}
.banner-main .porposalDiv .timeDiv .greyButton {
  padding: 5px 15px;
  display: flex
;
  align-items: center;
  grid-gap: 0px;
  gap: 0px;
  border-radius: 225px;
  background: #222;
  outline: none;
}
.banner-main .porposalDiv .timeDiv .noDiv .button_top .ptagssspan {
  color: #67717D;
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  padding: 5px 10px;
  height: 28px;
  display: flex
;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  background: rgba(103, 113, 125, 0.09);
  text-transform: capitalize;
}
.mydataa span {
  color: #FF0083;
  font-size: 10px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.mydataa .blank__div {
  color: #FF0083;
  font-size: 10px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}
.texted{
  h5 {
    color: #67717D !important;
    font-size: 10px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 120%;
    padding: 12px !important;
    border-radius: 10px !important;
    background: #E0ECFA;
    display: inline-flex
;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}
}
.excutedtext {
  grid-gap: 5px !important;
  gap: 5px !important;
}
.banner-main .porposalDiv .timeDiv .banner_parent_Div {
  padding: 15px;
  border-radius: 15px;
  background: #E0ECFA;
  width: 100%;
  display: flex
;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
.banner-main .porposalDiv .timeDiv .banner_parent_Div {
  padding: 15px !important;
  border-radius: 15px;
  background: #E0ECFA;
  width: 100%;
  display: flex
;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
}
  .governancebox{
    .Htag {
      color: #0E1120;
      text-align: center;
      font-size: 65px;
      font-style: normal;
      font-weight: 700;
      line-height: 110%;
      padding-top: 116px !important;
  }
  }

  .show-item {
    right: 67px !important;
    position: absolute !important;
    top: 78px !important;
    width: 275px !important;
    max-width: unset !important;
}
  .new_button_diss{
    display: none !important;
  }
  .banner-main .pinkbtn {
    margin-top: 0px !important;
    white-space: nowrap;
  }
}
@media (max-width: 601px) {
  .footorshadow {
    position: absolute;
    bottom: 74px !important;
    right: 0px;
}
  .submit-proposal-modal .modal-header .modal-title {
    color: #0E1120;
    color: var(--primary-color, #0E1120);
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    text-transform: capitalize;
    text-align: center;
    width: 100%;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .crossbutton_svvgg {
    cursor: pointer;
    width: 20px;
    height: 20px;
  }

  .main-submit {
    min-height: unset !important;
  }

  .banner-main .porposalDiv .walletDiv p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    text-transform: uppercase;
    color: #767676;
    margin-right: 0px;
  }

  .banner-main .porposalDiv .timeDiv {
    border-radius: 10px !important;
  }

  .banner-main .treasuryBox span:nth-child(4) {
    flex: 0.2 1;
    text-align: center;
    height: 100%;
    width: 1px;
    padding: 20px;
    display: none;
  }

  .banner-main .governancebox {
    padding-top: 32px;
  }

  .banner-main .porposalDiv .timeDiv .greyButtons {
    background: #f1f1f1;
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: 9px 15px;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    color: #ffffff;
    margin-top: 20px;
    margin-right: 29px;
  }

  .banner-main .treasuryBox .treasuryfirst span h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 27px;
  }

  .banner-main .porposalDiv .timeDiv .noDiv {
    display: flex;

    align-items: flex-start;
  }

  .banner-main .treasuryBox .treasuryfirst span:nth-child(2) {
    border-left: none;
  }

  .banner-main .treasuryBox .treasuryP {
    border-left: none;
  }

  //fontsizes
  .banner-main .governancebox h3 {
    font-size: 18px;
  }

  .banner-main .governancebox p {
    font-size: 15px;
    margin-bottom: 0px;
  }

  .button_new {
    position: absolute;
    right: 25px;
    top: 11px;
    z-index: 9999;
    display: flex;
    align-items: center;
    grid-gap: 16px;
    gap: 5px;
  }

  .banner-main .governancebox h3 {
    padding-top: 20px;
    padding-bottom: 20px;
  }

  .banner-main .treasuryBox .treasuryfirst span h4 {
    font-size: 32px;
  }

  .banner-main .countSection .forAganist .text-dangerr {
    font-size: 18px !important;
  }

  .banner-main .pinkbtn {
    color: #FFF;
    text-align: center;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    text-transform: capitalize;
    padding: 14px 24px;
    border-radius: 15px;
    background: #222D37;
    border: unset;
  }

  .banner-main .porposalDiv .porp {
    font-size: 22px;
  }

  .mydivphone {
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: 14px;
  }

  .banner-main .porposalDiv .porps {
    margin-right: 0px;
  }

  .banner-main {
    padding-top: 0px;
  }

  .banner-main .bg-primary {
    width: 85px !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .navbar-brand img {
    max-width: 108px;
  }

  .main-submit {
    padding-top: 40px !important;
  }

  .modal.show .modal-dialog {
    transform: none;
    margin: 20px;
  }


  .banner-main .porposalDiv .timeDiv {
    padding: 11px 3px;
  }

  .banner-main .comments .comments-heading h2 {
    white-space: nowrap;
    font-size: 21px !important;
  }

  .banner-main .comments .commentstextarea p {
    font-size: 18px;
  }

  .banner-main .comment .headingsss h2 {
    font-size: 21px !important;
  }

  .banner-main .comment .parent .right-content .doyle .doylehead {
    font-size: 17px;
  }

  .banner-main .comment .parent .right-content .doyle p {
    font-size: 14px;
  }

  .banner-main .comment .mainpara-comment .para p {
    font-size: 17px !important;
  }

  .buy-pioneer-modal11 .modal-dialog .modal-content {
    min-width: 100% !important;
  }

  .connect-wallet-btn {
    display: block !important;
    border-radius: 15px !important;
    background: #0E6AAD;
    color: #E8F2FF;
    border: unset !important;
    text-align: center;
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 100%;
    /* 16px */
    padding: 16px 20px !important;
    margin: 0 auto;
  }

  .connectwallet-mobile {
    .modal-header {
      border: none;
      padding: 20px;
      justify-content: center;

      .modal-title {
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 30px;
        color: #171515;
      }
    }

    .modal-content {
      background: #ffffff;
      border-radius: 15px;
    }

    .modal-body {
      padding: 20px;
    }

    .btn-close {
      background: url("../../Assets/close-icon.svg");
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      position: absolute;
      top: 20px;
      right: 25px;
      border: none;
      opacity: 1;
    }

    .connect-btn {
      background: rgba(255, 255, 255, 0.6);
      border: 1px solid #f2f2ff;
      border-radius: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 20px;

      .inner-content {
        display: flex;
        justify-content: center;
        align-items: center;

        h6 {
          font-style: normal;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          color: #171515;
        }
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #959595;
        margin-top: 25px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .banner-main .treasuryBox {
    grid-gap: 10px;
    gap: 10px;
    display: grid;
    grid-template-columns: 1fr;
    padding: 0px 20px;
    margin-top: 50px;
  }

  .main-submit .innersubmit {
    padding: 58px 60px;
    border-radius: 15px;
    border: 1px solid #DCE7F3;
    border: 1px solid var(--stroke, #DCE7F3);
    background: #E7F2FF;
    background: var(--frame-color, #E7F2FF);
    -webkit-backdrop-filter: unset !important;
    backdrop-filter: unset !important;
    margin-bottom: 30px !important;
  }

  .banner-main .porposalDiv .timeDiv .innnerptag {
    color: #0E1120 !important;
    -webkit-font-feature-settings: "liga" off, "clig" off;
    font-feature-settings: "liga" off, "clig" off;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    margin-top: 14px;
    width: 100%;
    flex-wrap: wrap;
  }

  .mydataa h3 {
    color: #0E1120 !important;
    -webkit-font-feature-settings: "liga" off, "clig" off;
    font-feature-settings: "liga" off, "clig" off;
    font-size: 24px !important;
    font-style: normal;
    font-weight: 500 !important;
    text-align: start !important;
    align-items: flex-start !important;
    line-height: 130%;
    width: 100%;
    flex-wrap: wrap;
    padding-top: 16px !important;
    padding-bottom: 25px !important;
  }

  .texted_mainns {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
  }

  .middle_proposal_section {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 60px;
    gap: 60px;
  }

  .banner-main .porposalDiv .timeDiv .banner_parent_Div {
    padding: 25px;
    border-radius: 15px;
    background: #E0ECFA;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;
  }

  .banner-main .porposalDiv .timeDiv .banner_parent_Div .bottom_side {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #DCE7F3;
  }
  .Editprofile .rightmain .maininner .innered .inning .upload-btn {
    color: #222D37;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    white-space: nowrap;
    line-height: 100%;
    padding: 18px 23px !important;
    border-radius: 15px;
    background: #D0DEEF;
    margin-bottom: 0px;
    cursor: pointer;
}
  .Htag {
    color: #0E1120;
    text-align: center;
    font-size: 36px !important;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
    padding-top: 50px !important;
  }

  .banner-main .governancebox .futttture {
    color: #0E1120 !important;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;
  }
}

.submit-proposal-modal {
  background: rgba(32, 30, 30, 0.65);
  backdrop-filter: blur(2px);

  .modal-header {
    padding: 0px;
    border: none;
    align-items: center;

    .modal-title {
      color: var(--primary-color, #0E1120);
      font-size: 34px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      /* 34px */
      text-transform: capitalize;
      text-align: center;
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .btn-close {
      background: url("../../Assets/add-circle.svg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border: none;
      padding: 15px;
    }
  }

  .modal-content {
    border-radius: 15px;
    background: var(--frame-color, #E7F2FF);
    backdrop-filter: blur(92px);
    padding: 30px;
  }

  .modal-dialog {
    max-width: 500px;
  }

  .modal-body {
    padding: 0px;

    button {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-bottom: 12px;
      color: var(--primary-color, #0E1120);
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: 100%;
      /* 20px */
      border-radius: 15px;
      background: #DFEAFC;
      border: 1px solid transparent;
      padding: 24px 20px;
      gap: 10px;

      &:hover {
        border: 1px solid var(--primary-color, #0E1120);

      }
    }

    .spec path {
      fill: #fff;
    }

    .change-my-color1 path {
      fill: #fff;
    }
  }
}

.submit-proposal-modal1 {
  background: rgba(32, 30, 30, 0.65);
  backdrop-filter: blur(2px);

  .modal-header {
    padding: 0px;
    border: none;
    align-items: center;

    .modal-title {
      color: var(--primary-color, #0E1120);
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: 100%;
      /* 20px */
      text-transform: capitalize;
      text-align: center;
      width: 100%;
      margin-bottom: 30px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .btn-close {
      background: url("../../Assets/add-circle.svg");
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border: none;
      padding: 15px;
    }
  }

  .modal-content {
    border-radius: 15px;
    background: var(--frame-color, #E7F2FF);
    backdrop-filter: blur(92px);
    padding: 30px;
  }

  .modal-dialog {
    max-width: 500px;
  }

  .modal-body {
    padding: 0px;
    overflow-y: hidden;

    .subbmitedvote {
      color: var(--button-text-color, #E8F2FF);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      min-height: 56px;
      padding: 15px 0px;
      border-radius: 15px;
      // background: var(--button-color, #222D37);
      border: unset;
      margin-top: 30px;
    }

    button {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 12px;
      color: var(--primary-color, #0E1120);
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      text-transform: capitalize;
      border-radius: 15px;
      background: #DFEAFC;
      border: 1px solid transparent;
      height: 72px;
      padding: 15px 0px;

      // &:hover {
      //   border: 1px solid var(--primary-color, #0E1120);

      // }
      &.active{
        border: 1px solid var(--primary-color, #0E1120);
      }
    }

    .spec path {
      fill: #fff;
    }

    .change-my-color1 path {
      fill: #fff;
    }
  }
}

.buy-pioneer-modal {
  background: rgba(32, 30, 30, 0.65);
  backdrop-filter: blur(2px);

  .close {
    opacity: 1;
  }

  .modal-dialog {
    max-width: 464px;
  }

  .modal-header {
    padding: 20px 30px;
  }

  .modal-body {
    padding: 30px 40px;
  }

  h5 {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    text-align: center;
    color: #ff0083;
    margin-bottom: 17px;
  }

  p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 120%;
    text-align: center;
    color: #767676;
    margin-bottom: 30px;
  }

  .buy-btn {
    background: #ffffff;
    border: 1.5px solid #ff0083;
    box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
    border-radius: 5px;
    width: 100%;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ff0083;
    padding: 14px 30px;
  }
}

.buy-pioneer-modal11 {
  .modal-dialog {
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-content {
      min-width: 571px;
      background: #ffffff;
      // border: 1.5px solid #FF0083;
      border-radius: 5px;

      .modal-header {
        padding: 0%;
        border-bottom: none;
        padding-top: 18px;
        position: relative;

        .modal-title {
          font-style: normal;
          font-weight: 700;
          font-size: 26px;
          line-height: 130%;
          text-align: center;
          color: #292929;
          width: 100%;
        }

        .close {
          position: absolute;
          top: 19px;
          right: 22px;
        }
      }

      .modal-body {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding-top: 58px;
        padding-right: 34px;
        padding-left: 34px;
        padding-bottom: 30px;

        p {
          font-style: normal;
          font-weight: 700;
          font-size: 22px;
          line-height: 130%;
          text-align: center;
          color: #292929;
          margin-bottom: 20px;

          span {
            color: #ff0083;
          }
        }

        h5 {
          font-style: normal;
          font-weight: 700;
          font-size: 23px;
          line-height: 130%;
          text-align: center;
          color: #ff0083;
          margin-top: 10px;
          margin-bottom: 20px;
        }

        .buy-btn {
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          padding: 16px 70px;
          background: #ff0083;
          border-radius: 5px;
          font-weight: 600;
          font-style: normal;
          font-size: 16px;
          line-height: 100%;
          color: #ffffff;
          width: 100%;
          margin-top: 22px;
        }
      }
    }
  }
}

.inner_navdataa {
  width: 100%;

  .data_outer {
    padding: 23px 16px;
    cursor: pointer;
    border-bottom: 1px solid #DCE7F3;
    background: #E7F2FF;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 12px;

    h2 {
      color: #0E1120;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      /* 16.8px */
    }

    h3 {
      color: #CF1A12;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 120%;
      /* 16.8px */
    }
  }

  .data_outer:hover {
    background: #E0ECFA;
  }
}

.dataa_top {
  padding: 16px;
  border-bottom: 1px solid #DCE7F3;
  background: #E0ECFA;
  width: 100%;
}

.show-item {
  border-radius: 18px;
  border: 1px solid #D6E0EB;
  position: absolute;
  background: #E0ECFA;
  top: 91px;
  right: 91px;
  z-index: 99;
  border-radius: 32px;
  width: 100%;
  max-width: 363.33px;
  padding: 0px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  gap: 0px;

  .mysett {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid #CDD8E3;
  }

  .innerset {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    cursor: pointer;

    .innersetleft {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 15px;

      .innersetimg {
        width: 43px;
        height: 43px;
        border-radius: 150px;
        object-fit: cover;
        object-position: center;
        display: flex;
        justify-content: center;
        align-items: center;

        .innerimg {
          width: 100%;
          height: 100%;
          border-radius: 150px;
          object-fit: cover;
          object-position: center;
        }
      }

      .lefttexts {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 5px;

        .lefthead {
          color: #0E1120;
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
          line-height: 100%;
          /* 16px */
        }

        .leftpara {
          color: #67717D;

          font-size: 12px;
          font-style: normal;
          font-weight: 400;
          line-height: 120%;
          /* 14.4px */
        }
      }
    }

    .innersetright {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      flex-direction: column;
      gap: 5px;

      .righthead {
        color: #0E1120;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;
        /* 19.2px */
      }

      .rightpara {
        color: #a6a6a6;
        font-size: 12px;
        font-weight: 400;
        margin: 0 !important;
        line-height: 12px;
      }
    }
  }

  h6 {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 100%;
    text-align: center;
    color: #fff;
    margin-bottom: 10px;

    span {
      font-weight: 700;
    }
  }
}

@media (max-width: 600px) {
  .mobile-show-pioneer {
    display: flex !important;
    position: unset;
    /* margin-bottom: 30px; */
    /* margin-top: 30px; */
    width: 100%;
    justify-content: center;
    /* align-items: baseline; */
    margin: 0 auto;
    margin-top: 30px;
  }

  .desktop-show-pioneer {
    // display: none;
  }

  .show-item {
    max-width: 100%;
  }

  .navbar .pinknavbtn .btnimage {
    width: 22px !important;
    height: 22px !important;
  }

  .navbar .pinknavbtn {
    font-size: 14px !important;
    height: 54px !important;
  }

  .navbar .btn-common {
    font-size: 14px;
    height: 54px !important;
  }

  .banner-main .treasuryBox .treasuryfirst span h5 {
    font-size: 18px;
  }

  .banner-main .treasuryBox .treasuryfirst span {
    padding: 24px;
  }

  .banner-main .treasuryBox .treasuryfirst {
    flex: 1 0 50%;
  }

  .banner-main .treasuryBox .treasuryfirst span .smallheading {
    font-size: 12px;
  }

  .banner-main .porposalDiv .timeDiv .greyButton {
    margin: 0 auto;

    h5 {
      font-size: 14px;
    }
  }

  .banner-main .porposalDiv .timeDiv .greyButtons {
    margin: 0 auto;
  }

  .banner-main .porposalDiv .timeDiv .noDiv {
    justify-content: center;
  }

  .banner-main .porposalDiv .timeDiv .noDiv p:nth-child(2) {
    text-align: start;
    white-space: nowrap;
    width: 700px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .excutedtext {
    justify-content: center;
    gap: 15px;
  }

  .banner-main .treasuryBox {
    align-items: flex-start;
  }

  .banner-main .top-borderrrr {
    border-top: 1px solid #4b4b4b;
  }

  .logo-tomidao-mobile {
    display: block !important;
    max-width: 164px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 30px;
  }
}

.estimated-text {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 100%;
  color: #fff;
}

@media (max-width: 600px) {
  .text-danger {
    color: #CF1A12 !important;
    font-size: 10px !important;
    font-style: normal;
    text-transform: unset !important;
    font-weight: 400 !important;
    line-height: 120%;
}
  .mainsection_nav {
    position: relative;
    padding: 12px 20px !important;
  }

  .estimated-text {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 100%;
    color: #2e3433;
  }

  .footer_data {
    padding: 0px !important;
  }

  .ptagggggs {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;

    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

a {
  color: rgb(73, 73, 73);
  text-decoration: underline;
}

.twice-btns {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
  margin-bottom: 20px;

  .artdao-btn {
    background: #fafbfc;
    border: 1px solid #ff0083;
    box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
    border-radius: 5px;
    padding: 16px 27px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    color: #ff0083;
    transition: 0.3s linear;

    &:hover {
      background: #ff0083;
      color: #ffffff !important;
    }
  }

  .forum-btn {
    background: #ffffff;
    border: 1px solid #ff0083;
    color: #ff0083 !important;
    border-radius: 5px;
    padding: 16px 27px;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 100%;
    transition: 0.3s linear;
    margin-left: 15px;
    transition: 0.3s linear;

    &:hover {
      background: #ff0083;
      border: 1px solid transparent;
      color: #ffffff !important;
      box-shadow: 0px 18px 30px -10px rgba(255, 0, 131, 0.15);
    }
  }
}



.Htag {
  color: #0E1120;
  text-align: center;
  font-size: 65px;
  font-style: normal;
  font-weight: 700;
  line-height: 110%;
  /* 71.5px */
  padding-top: 50px !important;
}

.rdw-editor-toolbar {
  // border: 1px solid #ff0083 !important;
  background: unset !important;
  border-radius: 20px 20px 0px 0px !important;
  margin-bottom: 10px !important;
}

.rdw-editor-main {
  border: 1px solid transparent ;
  border-radius: 15px !important;
  background: var(--text-feild-color, #DFEAFC) !important;
  color: var(--primary-color, #0E1120);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 100%;
  /* 16px */
  &:hover {
    border: 1px solid #BDCBDC ;


  }
  &:focus {
    border: 1px solid #0E1120 !important; /* Border on active state */
    outline: none; /* Optional: Removes the default outline on focus */
  }
}

.copyytextual_div{
  padding: 20px;
  border-radius: 15px;
  border: 1px solid var(--stroke, #DCE7F3);
  background: var(--text-feild-color, #DFEAFC);
  color: var(--primary-color, #0E1120);
font-size: 16px;
font-style: normal;
font-weight: 400;
line-height: 140%; /* 22.4px */
}
.public-DraftStyleDefault-ltr {
  color: var(--primary-color, #0E1120);
}

.rdw-editor-toolbar {
  // display: none !important;
}

.excutedtext {
  gap: 20px;
}

.btnimage {
  border-radius: 15px;
  background: #D0DEEF;
  width: 48px;
  height: 48px;
}

.taggeeeumh {
  width: 156px;
  height: 156px;
}

.mydatacollectin {
  color: var(--primary-color, #0E1120);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  /* 26px */
  margin-bottom: 10px;
}

.qussdiv {}

.rdw-option-wrapper {
  border: unset !important;
  background: #FF0083 !important;
}

.rdw-option-wrapper:hover {
  box-shadow: unset !important;
}

.rdw-dropdown-wrapper {
  height: 23px !important;
  background: #FF0083 !important;
  border: unset !important;
}

.rdw-dropdown-wrapper:hover {
  box-shadow: unset !important;
  color: unset !important;
}

.rdw-editor-toolbar {
  span {
    color: #000000 !important;
  }

  ::-webkit-scrollbar {
    display: none;
  }
}

.rdw-dropdown-optionwrapper {
  background-color: #000000 !important;
}

.rdw-dropdown-optionwrapper {
  color: #ff0083 !important;
}

.rdw-dropdown-optionwrapper {
  color: #ff0083 !important;
}

.rdw-dropdown-optionwrapper {
  color: #ff0083 !important;
  border: unset !important;
}

.rdw-dropdownoption-active {
  background-color: #ff0083 !important;
  color: #000000 !important;
}

.rdw-dropdownoption-highlighted {
  background-color: #ff0083 !important;
  color: #000000 !important;
}

.rdw-dropdown-optionwrapper:hover {
  box-shadow: unset !important;
  background-color: #FFFFFF;
}

.categorymodal {
  .modal-dialog {
    .modal-content {
      border-radius: 15px;
      border: 1px solid rgba(255, 255, 255, 0.10);
      background: linear-gradient(180deg, rgba(255, 255, 255, 0.03) 0%, rgba(120, 120, 120, 0.03) 100%);
      border: 1px solid #FFFFFF1A;
      backdrop-filter: blur(160px);

      .modal-header {
        border: unset;
        // cursor: pointer;
      }

      .modal-body {
        padding: 0px;

        .modal__body {
          display: flex;
          flex-direction: column;
          align-items: center;

          h5 {
            color: #FFF;
            text-align: center;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 100%;
            /* 24px */
            padding-top: 30px;
          }

          p {
            color: #BCBCBC;
            text-align: center;
            padding-top: 10px;
            padding-bottom: 10px;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 140%;
            /* 22.4px */
          }

          h2 {
            color: #FF0083;
            text-align: center;
            padding-bottom: 30px;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }

          button {
            color: #FFF;
            border: unset;
            font-size: 16px;
            margin: 0 auto;
            display: flex;
            justify-content: center;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
            text-transform: capitalize;
            width: 218px;
            padding: 15px 0px;
            border-radius: 120px;
            background: #FF0083;
            margin-bottom: 54px;
          }

          // button:hover{
          //   color: #FFFFFF;
          //   background-color: var(--lightPrimary);
          //   border-color: var(--lightPrimary);
          // }
        }
      }
    }
  }
}

.modal-header .btn-close {
  background: url(../../Assets/cross.svg);
  background-repeat: no-repeat;
  box-shadow: unset;
  opacity: unset;
  height: 30px;
  width: 20px;
  cursor: pointer;
  margin-top: 2px;
}

.modal-backdrop.fade {
  opacity: 0 !important;
  background: rgb(0 0 0 / 50%) !important;
  // z-index: 9999999 !important;
}

.modal-open .modal {


  background: rgba(0, 0, 0, 0.10) !important;
  backdrop-filter: blur(12px) !important;
}

.comingsoon_text {
  color: var(--button-text-color, #E8F2FF);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
  padding: 4px 8px;
  border-radius: 17px;
  background: var(--button-color, #222D37);
}

.crossbutton_svvgg {
  cursor: pointer;
}

.texted_mainns {
  display: flex;
  align-items: center;
  justify-content: space-between;

  .votesDiv {
    color: #FF0083 !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;

  }

  p {
    color: #FF0083 !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
        /* 16.8px */
        padding: 12px;
        display: inline-flex;
        border-radius: 10px;
        background: #E0ECFA;
    /* 16.8px */
  }
}
.connect__div{
  display: flex;
  align-items: center;
  gap: 5px;
}
@media (max-width: 400px) {
  .connect-wallet-btn {
      display: block !important;
      border-radius: 15px !important;
      background: #0E6AAD;
      color: #E8F2FF;
      border: unset !important;
      text-align: center;
      font-size: 12px !important;
      font-style: normal;
      font-weight: 400 !important;
      line-height: 100%;
      padding: 16px 12px !important;
      margin: 0 auto;
  }
}
@media (max-width: 600px) {
  .banner-main .treasuryBox {
      grid-gap: 10px;
      grid-gap: 10px;
      gap: 10px;
      display: grid
;
      grid-template-columns: 1fr;
      padding: 0px 0px;
      margin-top: 50px;
  }
  .top_proposal_div {
    padding: 0px 0px !important;
}
.banner-main .porposalDiv .timeDiv .innnerptag {
  color: #0E1120 !important;
  -webkit-font-feature-settings: "liga" off, "clig" off;
  font-feature-settings: "liga" off, "clig" off;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;
  margin-top: 14px;
  width: 100%;
  flex-wrap: wrap;
}
.ptagggggs {
  -webkit-box-orient: vertical;
  -webkit-line-clamp: unset !important;
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
}
.myyydirection .rightmain {
  display: flex
;
  align-items: flex-start;
  grid-gap: 0px;
  gap: 0px !important;
  flex-direction: column;
}
.copy {
  right: 8px !important;
  top: 19px !important;
}
.texted_mainns{
  h5 {
    color: #67717D !important;
    font-size: 10px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 120%;
    padding: 12px !important;
    border-radius: 10px !important;
    background: #E0ECFA;
    display: inline-flex
;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
}
}
.main_mid h5 {
  color: #67717D;
  text-align: center;
  font-size: 10px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-transform: capitalize;
  padding: 5px 12px;
  border-radius: 15px;
  background: rgba(103, 113, 125, 0.09);
}
}